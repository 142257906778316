@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.cdnfonts.com/css/itc-avant-garde-gothic-std-book");
@import url("https://fonts.googleapis.com/css?family=Nunito");
@import url("https://fonts.cdnfonts.com/css/brittany-signature");
@import url("https://fonts.cdnfonts.com/css/imstory");

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased; */
  -moz-osx-font-smoothing: grayscale;
}

.btn-primary {
  background: #00a650;
}

a {
  text-decoration: none;
}

