.footer-bg {
    background: #00a650;
}

.link {
    height: 27px;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 27px;

    color: #e6fff2;
}

.footer-icon {
    height: 50px;
    width: 90px;
}

.footer-left--text {
    width: 498px;
    height: 84px;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 154.5%;

    color: #3a5447;
}

.nlrc {
    height: 100px;
    width: 100px;
}

.set {
    font-size: 14px;
}

@media screen and (max-width: 968px) {
    .nlrc {
       margin-left: auto;
       margin-right: auto;
    }

    .footer-left--text {
        width: auto;
        height: 68px;
       
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 154.5%;
        text-align: center;
        margin-bottom: 50px;
    }

    .footer-icon {
        margin-left: calc(32vh - 100% / 30);
    }
}

.colored {
    height: 120px;
    background: #e5fff2 100%;
}